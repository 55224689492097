import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';


const ShowInMobile = ({ children, breakpoint }) => {

  const [width, setWidth] = useState(0);
  
  useEffect(() => {
    setWidth(window.innerWidth);
  })

  if(width <= breakpoint){
    return <></>
  }else{
    return (
      children
    )
  }
}

export default ShowInMobile
