import React from 'react';
import PropTypes from 'prop-types';

const IconQuotes = ({ color }) => (
  <svg width="28" height="21" viewBox="0 0 28 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M22.4654 10.3104C22.6205 8.86066 23.0398 7.66526 23.7235 6.7242C24.4187 5.76728 25.5076 4.98566 26.9904 4.37934C27.2888 4.2573 27.6032 4.14236 27.9336 4.03452L26.0199 3.34595e-07C23.9323 0.735701 22.2158 1.74706 20.8704 3.0345C19.5019 4.34476 18.574 5.80454 18.0869 7.4138C17.5998 9.02299 17.3563 11.2299 17.3563 14.0345L17.3563 21L27.1333 21L27.1333 11.3103L22.4014 11.3104C22.4098 10.9646 22.4311 10.6312 22.4654 10.3104ZM19.0441 7.70353C18.598 9.17696 18.3563 11.2719 18.3563 14.0345L18.3563 20L26.1333 20L26.1333 12.3103L21.3768 12.3104L21.4017 11.286C21.4513 9.24355 21.9255 7.49772 22.9145 6.13648C23.7409 4.9989 24.985 4.12684 26.5612 3.47462L25.5125 1.26353C23.9192 1.92032 22.6087 2.75527 21.562 3.75683C20.3018 4.96344 19.4752 6.27901 19.0441 7.70353Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.933593 14.0345C0.933592 11.2299 1.18871 9.02299 1.69909 7.4138C2.18617 5.80455 3.11398 4.34477 4.48254 3.03451C5.82793 1.74707 7.53283 0.735704 9.59724 3.20603e-06L11.5109 4.03452C11.1812 4.14345 10.8673 4.25962 10.5695 4.38304C9.10734 4.98884 8.02942 5.76922 7.33562 6.7242C6.63298 7.66527 6.21017 8.86067 6.06728 10.3104C6.03566 10.6312 6.01774 10.9646 6.01355 11.3104L10.7107 11.3104L10.7107 21L0.933594 21L0.933593 14.0345ZM9.09134 1.26681C7.51986 1.92253 6.21997 2.75605 5.17409 3.75683C3.91386 4.96344 3.08737 6.27899 2.65621 7.7035L2.6523 7.71613C2.18666 9.18423 1.93359 11.2742 1.93359 14.0345L1.93359 20L9.71068 20L9.71068 12.3104L5.00133 12.3104L5.01362 11.2982C5.03848 9.25048 5.51252 7.49733 6.53041 6.13119C7.35415 4.9999 8.58466 4.1302 10.1404 3.47853L9.09134 1.26681Z" fill={color} />
  </svg>
);

IconQuotes.defaultProps = {
  color: '#573DA8',
};

IconQuotes.propTypes = {
  color: PropTypes.string,
};

export default IconQuotes;
