import { graphql, useStaticQuery } from 'gatsby';

export const getImage = () => {
  const data = useStaticQuery(graphql`
  query {
    allImageSharp {
      nodes {
        fixed{
          originalName
            ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
  `);

  return data;
};
