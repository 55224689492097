import React, { useEffect, useState } from 'react';
import {
  Box, makeStyles, Typography, useTheme,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import Slider from 'react-slick';
import Img from 'gatsby-image';
import IconQuotes from '../atoms/IconQuotes';
import Customer from '../../static/files/Customer.json';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LazyImage from '../atoms/LazyImage';
import { getImage } from '../functions/useStaticImages';

const useStyles = makeStyles((theme) => ({
  title: {
    '@media screen and (max-width: 900px)': {
      textAlign: 'center',
    },
  },
  comments: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gap: '20px',
    marginTop: '30px',

    '@media screen and (max-width: 959px)': {
      display: 'none',
    },
  },

  mobileComments: {
    display: 'none',
    '@media screen and (max-width: 959px)': {
      display: 'inline',
    },
  },
  slider: {
    marginTop: '25px',

    '& .slick-slide > div': {
      margin: '0 5px',
    },
    '& .slick-list': {
      margin: '0 -5px',
    },
    '& .slick-dots li button:before': {
      marginTop: '8px',
      color: theme.palette.primary.main,
    },
  },
  box: {
    padding: '50px 30px',
    boxSizing: 'border-box',
    background: theme.palette.secondary.contrastText,
    boxShadow: '0px -30px 70px rgba(58, 53, 104, 0.102)',
    borderRadius: '15px',

    '@media screen and (max-width: 959px)': {
      boxShadow: 'none',
      minWidth: '20px',
      height: '400px',
    },

    '@media screen and (max-width: 350px)': {
      height: '450px',
    },

    '& .name': {
      display: 'flex',
      alignItems: 'center',
      columnGap: '25px',
    },

    '& .description': {
      marginTop: '25px',
      display: 'grid',
      gridTemplateColumns: 'min-content auto',
      columnGap: '15px',
      alignItems: 'flex-start',

      '& svg': {
        marginTop: '5px',
      },
    },
  },
}));

const CustomerComments = ({ color }) => {
  const classes = useStyles();
  const theme = useTheme();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const images = getImage();
  const VittudeImg = images.allImageSharp.nodes.find((n) => n.fixed.originalName === 'cliente-conube-1.png').fixed;
  const JqlImg = images.allImageSharp.nodes.find((n) => n.fixed.originalName === 'cliente-conube-2.png').fixed;
  const UmRecadoImg = images.allImageSharp.nodes.find((n) => n.fixed.originalName === 'cliente-conube-3.png').fixed;

  const renderBox = ({
    name, company, src, comment,
  }) => (
    <Box className={classes.box}>
      <Box className="name">
        {/* <img src={src} alt={`${name} da empresa ${company}`} /> */}
        <LazyImage>
          <Img fixed={src} alt={`${name} da empresa ${company}`} />
        </LazyImage>
        {/* <LazyImage img={src} alt={`${name} da empresa ${company}`} /> */}
        <Box>
          <Typography variant="body1">{name}</Typography>
          <Typography variant="button">{company}</Typography>
        </Box>
      </Box>
      <Box className="description">
        <IconQuotes color={theme.palette[color].main} />
        <Typography variant="body1">{comment}</Typography>
      </Box>
    </Box>
  );

  return (
    <Box>
      <Typography variant="h3" component="h2" className={classes.title}>
        Ajudamos
        <strong> milhares de empresas </strong>
        a simplificarem suas contabilidades todos os dias!
      </Typography>

      <Box className={classes.comments}>
        {renderBox({ ...Customer.comments[0], src: VittudeImg })}
        {renderBox({ ...Customer.comments[1], src: JqlImg })}
        {renderBox({ ...Customer.comments[2], src: UmRecadoImg })}
      </Box>
      <Box className={classes.mobileComments}>
        {/* // eslint-disable-next-line react/jsx-props-no-spreading */}
        <Slider className={classes.slider} {...settings}>
          {renderBox({ ...Customer.comments[0], src: VittudeImg })}
          {renderBox({ ...Customer.comments[1], src: JqlImg })}
          {renderBox({ ...Customer.comments[2], src: UmRecadoImg })}
        </Slider>
      </Box>
    </Box>
  );
};

CustomerComments.defaultProps = {
  color: 'primary',
};

CustomerComments.propTypes = {
  color: PropTypes.string,
};

export default CustomerComments;
